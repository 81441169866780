import React from 'react';

import PropTypes from 'prop-types';
import './_ButtonNavigation.scss';

const ButtonNavigation = ({
  btnIcon,
  idComponentContext,
  index,
  onClick,
  btnDiretion,
  ariaLabel,
  shouldDisable,
}) => (
  <button
    id={`btnAction_${idComponentContext}_${index}_carousel`}
    type="submit"
    aria-label={ariaLabel}
    className={`itau-action-nav-button ${btnDiretion}`}
    onClick={() => onClick(btnDiretion)}
    aria-live="polite"
    aria-disabled={shouldDisable}
  >
    <i className={`icon ${btnIcon}`} aria-hidden />
  </button>
);

ButtonNavigation.propTypes = {
  idComponentContext: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  btnIcon: PropTypes.string,
  onClick: PropTypes.func,
  btnDiretion: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired,
  shouldDisable: PropTypes.bool.isRequired,
};

ButtonNavigation.defaultProps = {
  btnIcon: 'icon-itaufonts_seta',
  onClick: () => {},
  btnDiretion: 'right',
};

export default ButtonNavigation;
