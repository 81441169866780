import React from 'react';

import { PropTypes } from 'prop-types';

import './_ProductDetailItem.scss';
import getHref from '../../../../../util/htmlTagHandlers/getHref';
import parseSanitizedHtml from '../../../../../util/parseSanitizedHtml';

const idComponent = 'productDetailItem';

const ProductDetailItem = ({
  idComponentContext,
  itemIcon,
  itemTitle,
  itemText,
  itemLink,
  itemUrl,
  itemAriaLabel,
  index,
}) => (
  <div
    className="productdetailwhitelabel-item-wrapper"
    id={`${index}_${idComponent}_${idComponentContext}`}
  >
    <i
      className={`icon ${itemIcon}`}
      id={`imgIcon${index}_${idComponent}_${idComponentContext}`}
      aria-hidden
    />

    <div className="item-content">
      <h3
        className="item-title"
        id={`lblTitle${index}_${idComponent}_${idComponentContext}`}
      >
        {itemTitle}
      </h3>

      <div className="item-description">
        <p
          className="item-text"
          id={`lblText${index}_${idComponent}_${idComponentContext}`}
        >
          {parseSanitizedHtml(itemText)}
          {itemUrl !== '' && (
            <a
              id={`lnkNav${index}_${idComponent}_${idComponentContext}`}
              className="item-link"
              href={getHref(itemUrl)}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={itemAriaLabel}
            >
              {itemLink}
            </a>
          )}
        </p>
      </div>
    </div>
  </div>
);

ProductDetailItem.propTypes = {
  idComponentContext: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  itemIcon: PropTypes.string.isRequired,
  itemTitle: PropTypes.string.isRequired,
  itemText: PropTypes.string.isRequired,
  itemLink: PropTypes.string,
  itemUrl: PropTypes.string,
  itemAriaLabel: PropTypes.string,
};

ProductDetailItem.defaultProps = {
  itemLink: '',
  itemUrl: '',
  itemAriaLabel: '',
};

export default ProductDetailItem;
