import React, { useState } from 'react';

import PropTypes from 'prop-types';
import './_NavMenu.scss';

function NavMenu({
  idComponentContext,
  reference,
  componentRef,
  scrolled,
  navLogo,
  navLogoNegative,
  navLogoAlt,
  navLinks,
}) {
  const idComponent = 'navMenu';
  const [hover, setHover] = useState(false);

  function toggleHover(bool) {
    setHover(bool);
  }

  function logoStyle() {
    return {
      backgroundImage: `url(${hover || scrolled ? navLogoNegative : navLogo})`,
    };
  }

  return (
    <nav
      role="navigation"
      ref={componentRef}
      className="navmenuwhitelabel-wrapper"
      id={`${idComponent}_${idComponentContext}`}
      onMouseOver={() => toggleHover(true)}
      onFocus={() => toggleHover(true)}
      onMouseOut={() => toggleHover(false)}
      onBlur={() => toggleHover(false)}
    >
      <div className="container">
        <div
          className="logo-left"
          role="img"
          aria-label={navLogoAlt}
          id={`imgLogo_${idComponent}_${idComponentContext}`}
          style={logoStyle()}
        />

        <div className="content-right">
          <ul className="content-list">
            {navLinks &&
              navLinks.map((contentItem, index) => {
                if (contentItem.section.text !== '') {
                  return (
                    <li key={contentItem.section.text} className="content-item">
                      <a
                        id={`lnkNav${index}_${idComponent}_${idComponentContext}`}
                        href={reference(contentItem.section.url)}
                        className="link"
                      >
                        {contentItem.section.text}
                      </a>
                    </li>
                  );
                }
                return null;
              })}
          </ul>
        </div>
      </div>
    </nav>
  );
}

NavMenu.propTypes = {
  idComponentContext: PropTypes.string.isRequired,
  reference: PropTypes.func.isRequired,
  componentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  scrolled: PropTypes.bool.isRequired,
  navLogo: PropTypes.string.isRequired,
  navLogoNegative: PropTypes.string.isRequired,
  navLogoAlt: PropTypes.string,
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      LINKS: PropTypes.string,
    })
  ).isRequired,
};

NavMenu.defaultProps = {
  navLogoAlt: '',
};

export default NavMenu;
