import React, { Component } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import environment from '../../config/environmentVariables';
import { sendMessage } from '../../endpoints/googleChatMessage';
import DefaultError from '../../pages/DefaultError/DefaultError';
import setLocalPathnameUrl from '../../routes/setLocalPathnameUrl';
import store from '../../store';
import { Creators as AppActions } from '../../store/ducks/app';
import { getQueryParam } from '../../util/getQueryParam';
import App from '../App';

export class AppDn extends Component {
  constructor() {
    super();
    this.state = {
      hasValidParams: this.hasValidUrlParams(),
    };
  }

  componentDidMount() {
    const { GOOGLE_CHAT_API_INVALID_URL_PARAMS } = environment;
    const { hasValidParams } = this.state;
    setLocalPathnameUrl(
      window.location.pathname.concat(window.location.search)
    );

    if (hasValidParams) {
      store.dispatch(AppActions.appInit());
    } else {
      sendMessage(
        new Error('Os parâmetros da URL estão inválidos'),
        'Fluxo de DN',
        GOOGLE_CHAT_API_INVALID_URL_PARAMS
      );
    }
  }

  hasValidUrlParams = () => {
    return this.hasValidDnParam() && this.hasValidIdParam();
  };

  hasValidDnParam = () => {
    const dnParamValue = getQueryParam('dn');
    return dnParamValue && dnParamValue !== 'null';
  };

  hasValidIdParam = () => {
    const idParamValue = getQueryParam('id');
    return idParamValue && idParamValue !== 'null';
  };

  render() {
    const { hasValidParams } = this.state;
    if (hasValidParams) {
      return <App />;
    }
    return <DefaultError removeButton retryAction={false} />;
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators(AppActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppDn);
