import React from 'react';

import PropTypes from 'prop-types';
import './_DescriptionItem.scss';

const DescriptionItem = ({ icon, title, altTitle }) => (
  <div className="itau-description-item-wrapper">
    <i className={`description-item-icon ${icon}`} aria-hidden />
    <p className="description-item-title">{title}</p>
    <p className="description-item-alt-title">{altTitle}</p>
  </div>
);

DescriptionItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  altTitle: PropTypes.string,
};

DescriptionItem.defaultProps = {
  icon: '',
  title: '',
  altTitle: '',
};

export default DescriptionItem;
