import React from 'react';

import PropTypes from 'prop-types';

import './_ContentItemWeb.scss';

const ContentItem = ({
  title,
  imageUrl,
  text,
  linkUrl,
  linkAria,
  idComponentContext,
  index,
}) => (
  <div className="component-benefitlist-contentitem-web-whitelabel-wrapper">
    <div className="card">
      <img src={imageUrl} alt="" className="image" aria-hidden />
      <h3
        className="title"
        id={`lblTitle2_${idComponentContext}_BenefitCard${index}`}
      >
        {title}
      </h3>

      <div className="text">{text}</div>

      {linkUrl !== '' && (
        <a
          id={`lnkNav_${idComponentContext}_BenefitCard${index}`}
          className="link"
          href={linkUrl}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={linkAria}
        >
          <i className="icon icon-itaufonts_mais" aria-hidden />
        </a>
      )}
    </div>
  </div>
);

ContentItem.propTypes = {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
  linkUrl: PropTypes.string.isRequired,
  linkAria: PropTypes.string.isRequired,
  idComponentContext: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default ContentItem;
