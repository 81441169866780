export default {
  CONTENT_TITLE: 'algo deu errado :(',
  CONTENT_DESCRIPTION: 'Não foi possível carregar as informações.',
  CONTENT_LINK: 'tentar novamente',
  CONTENT_LINK_SECOND_RETRY: 'tentar mais uma vez',
  CONTENT_LINK_NO_RETRY: 'tentar novamente mais tarde.',
  CONTENT_BUTTON: 'voltar pra página do cartão',
  IE: {
    CONTENT_TITLE: 'algo deu errado :(',
    CONTENT_DESCRIPTION:
      'Esta página não pode ser exibida no Internet Explorer. O que fazer agora?',
    CONTENT_DESCRIPTION_LIST: [
      'copie o link;',
      'feche esta página;',
      'cole o link na barra de endereço de outro navegador.',
    ],
    CONTENT_BUTTON: 'copiar endereço do link',
  },
};
