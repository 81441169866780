import ERROR_PAGE from './ERROR_PAGE';
import HEADER from './HEADER';
import PAGE_INTRO from './PAGE_INTRO';
import PAGINATION_BUTTON from './PAGINATION_BUTTON';

const RESOURCES = {
  ERROR_PAGE,
  HEADER,
  PAGE_INTRO,
  PAGINATION_BUTTON,
};

export default RESOURCES;
