import React, { Component } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import constants from '../../constants';
import ButtonNavigation from '../ButtonNavigation/ButtonNavigation';
import './_SimpleCarousel.scss';

const {
  RESOURCES: {
    PAGINATION_BUTTON: {
      NAVEGATION_LEFT_DIRECTION,
      NAVEGATION_RIGHT_DIRECTION,
      NAVEGATION_LEFT_ICON,
      NAVEGATION_RIGHT_ICON,
    },
  },
  SCREEN_SIZES,
} = constants;

class ItauSimpleCarousel extends Component {
  handleScroll = _.throttle(() => {
    const pageIndicator = this.getPageIndicator();
    let carouselLeftButtonDisabled;
    let carouselRightButtonDisabled;
    if (pageIndicator <= 1) {
      carouselLeftButtonDisabled = true;
      carouselRightButtonDisabled = false;
    } else {
      carouselLeftButtonDisabled = false;
      carouselRightButtonDisabled = true;
    }
    this.setState({
      carouselLeftButtonDisabled,
      carouselRightButtonDisabled,
    });
  }, 150);

  constructor(props) {
    super();
    this.sliderRef = React.createRef();
    this.contentScreenPercentage = props.contentScreenPercentage;
    this.contentScreenPercentageTablet = props.contentScreenPercentage;
    this.contentScreenPercentageDesktop = props.contentScreenPercentageDesktop;
    this.contentAppMargin = props.contentAppMargin;
    this.cardQt = props.children.length || 1;
    this.observer = new IntersectionObserver(this.setTransparency, {
      root: this.sliderRef.current,
      threshold: [0, 0.15, 0.3],
    });

    this.state = {
      carouselLeftButtonDisabled: true,
      carouselRightButtonDisabled: false,
    };
  }

  componentDidMount() {
    this.createPositionList();
  }

  componentWillUnmount() {
    this.handleScroll.cancel();
  }

  createPositionList = () => {
    this.sliderRef.current
      .querySelectorAll('.component-benefitslist-slider > li')
      .forEach(item => {
        this.observer.observe(item);
      });
  };

  setTransparency = entries => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        return;
      }
      if (entry.intersectionRect.width >= entry.target.clientWidth * 0.2) {
        entry.target.classList.remove('faded');
      } else {
        entry.target.classList.add('faded');
      }
    });
  };

  getContentScreenPercentage = screenWidth => {
    if (screenWidth >= SCREEN_SIZES.DESKTOP) {
      return this.contentScreenPercentageDesktop;
    }
    if (screenWidth >= SCREEN_SIZES.TABLET_WIDE) {
      return this.contentScreenPercentageTablet;
    }
    return this.contentScreenPercentage;
  };

  getPageIndicator = () => {
    const scrollingCounter = this.sliderRef.current
      ? this.sliderRef.current.scrollLeft
      : '';
    const questionCardSize =
      this.getContentScreenPercentage(window.innerWidth) *
      document.documentElement.clientWidth;
    const fullScroll = Math.floor(questionCardSize + this.contentAppMargin);
    const smallScroll = Math.floor(
      questionCardSize + this.contentAppMargin * 0.5
    );

    let pageIndicator = 1;
    let scrollAux = scrollingCounter;

    if (pageIndicator <= this.cardQt && scrollAux > smallScroll / 2) {
      scrollAux -= smallScroll;
      pageIndicator += 1;

      while (scrollAux > fullScroll / 2 && pageIndicator <= this.cardQt - 2) {
        scrollAux -= fullScroll;
        pageIndicator += 1;
      }
      if (pageIndicator >= this.cardQt - 2) {
        if (scrollAux > smallScroll / 2) {
          scrollAux -= smallScroll;
          pageIndicator += 1;
        }
      }
    }

    return pageIndicator;
  };

  onClickNavegation = direction => {
    if (direction === NAVEGATION_LEFT_DIRECTION) {
      this.sliderRef.current.scrollBy({
        left: -this.sliderRef.current.offsetWidth,
        behavior: 'smooth',
      });
    } else {
      this.sliderRef.current.scrollBy({
        left: this.sliderRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  };

  render() {
    const { children, idComponentContext } = this.props;
    const {
      carouselLeftButtonDisabled,
      carouselRightButtonDisabled,
    } = this.state;
    return (
      <div className="ItauSimpleCarousel">
        <div className="common-question-wrapper" onScroll={this.handleScroll}>
          <div className="component-benefitslist">
            <ButtonNavigation
              btnIcon={NAVEGATION_LEFT_ICON}
              idComponentContext={idComponentContext}
              index={0}
              onClick={this.onClickNavegation}
              btnDiretion={NAVEGATION_LEFT_DIRECTION}
              ariaLabel="Benefícios anteriores"
              shouldDisable={carouselLeftButtonDisabled}
            />

            <ul className="component-benefitslist-slider" ref={this.sliderRef}>
              {children}
            </ul>

            <ButtonNavigation
              btnIcon={NAVEGATION_RIGHT_ICON}
              idComponentContext={idComponentContext}
              index={1}
              onClick={this.onClickNavegation}
              btnDiretion={NAVEGATION_RIGHT_DIRECTION}
              ariaLabel="Próximos benefícios"
              shouldDisable={carouselRightButtonDisabled}
            />
          </div>
        </div>
      </div>
    );
  }
}

ItauSimpleCarousel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  idComponentContext: PropTypes.string.isRequired,
  contentScreenPercentage: PropTypes.number,
  // contentScreenPercentageTablet: PropTypes.number,
  contentScreenPercentageDesktop: PropTypes.number,
  contentAppMargin: PropTypes.number,
};

ItauSimpleCarousel.defaultProps = {
  contentScreenPercentage: 0.825,
  // contentScreenPercentageTablet: 0.3,
  contentScreenPercentageDesktop: 0.09,
  contentAppMargin: 24,
};

export default ItauSimpleCarousel;
