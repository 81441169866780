/**
 * * Types
 */
export const Types = {
  ROUTES_CONTROL_CHANGE_PERMITION: 'routesControl/CHANGE_PERMITION',
};

/**
 * * Reducer
 */
const INITIAL_STATE = {
  permitedRoutes: {
    home: true,
    acquisition: false,
  },
};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.ROUTES_CONTROL_CHANGE_PERMITION:
      return {
        ...state,
        permitedRoutes: {
          ...state.permitedRoutes,
          [action.payload.routesPermition.route]:
            action.payload.routesPermition.permition,
        },
      };
    default:
      return state;
  }
}

/**
 * * Actions
 */
export const Creators = {
  changePermition: routesPermition => ({
    type: Types.ROUTES_CONTROL_CHANGE_PERMITION,
    payload: { routesPermition },
  }),
};
