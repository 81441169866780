import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { AppCardCode } from '../App/containers/AppCardCode';
import { AppDn } from '../App/containers/AppDn';
import AppMidia from '../App/containers/AppMidia';
import environment from '../config/environmentVariables/index';
import constants from '../constants';
import { sendMessage } from '../endpoints/googleChatMessage';
import CardAcquisition from '../pages/CardAcquisition/CardAcquisition';
import DefaultError from '../pages/DefaultError/DefaultError';
import getLocalPathnameUrl from './getLocalPathnameUrl';
import ProtectedRoute from './ProtectedRoute';

const {
  ROUTES: {
    HOME,
    CARD_ACQUISITION,
    CARDS,
    CAMPAIGNS: { MIDIA },
  },
} = constants;

const { GOOGLE_CHAT_API_URL } = environment;

export default function Routes() {
  const acquisitionPermition = useSelector(
    state => state.routesControl.permitedRoutes.acquisition
  );

  return (
    <ErrorBoundary
      onError={error =>
        sendMessage(error, 'Erro genérico de javascript.', GOOGLE_CHAT_API_URL)
      }
      FallbackComponent={DefaultError}
    >
      <BrowserRouter>
        <Switch>
          <Route exact path={HOME} component={AppDn} />
          <Route exact path={CARDS} component={AppCardCode} />
          <Route path={MIDIA} component={AppMidia} />
          <ProtectedRoute
            redirectionRoute={getLocalPathnameUrl('@LandingPage:urlPath')}
            path={CARD_ACQUISITION}
            canActivate={acquisitionPermition}
            component={CardAcquisition}
          />
          <Route component={DefaultError} />
        </Switch>
      </BrowserRouter>
    </ErrorBoundary>
  );
}
