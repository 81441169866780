function analyticsPage(errorDescription) {
  return {
    secao: 'MemberGetMember',
    subSecao1: 'CartaoIndicadoPorAmigo',
    url: 'www.indique.credicard.com.br',
    nome: 'CC:NL:NCC:MemberGetMember:CartaoIndicadoPorAmigo:Erro',
    templateName: 'home',
    nomeDoErro: errorDescription,
  };
}

const analyticsClick = 'BTN:NCC:MemberGetMember:PedirCartao:CT:CP';

export { analyticsPage, analyticsClick };
