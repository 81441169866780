/* eslint-disable import/no-dynamic-require */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import Loading from '../../components/Loading/Loading';
// import WebpageHeader from '../../components/WebpageHeader/WebpageHeader';
import constants from '../../constants';
import { Creators as AppActions } from '../../store/ducks/app';
import concatQuerystring from '../../util/concatQuerystring';
import { getQueryParam } from '../../util/getQueryParam';
import isObjEmpty from '../../util/isObjEmpty';
// import IFrame from './IFrame/IFrame';
import './_CardAcquisition.scss';

const {
  QUERY_PARAMS: {
    EMBEDDED_FORM: { CHPRAS },
  },
} = constants;

// const getImg = img => {
//   return require(`../../assets/${img}`);
// };

class CardAcquisition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      queryString: '',
    };
  }

  componentDidMount() {
    const queryString = concatQuerystring(['id', 'dn']);

    this.setState({ queryString });
  }

  getMainCardLogo = () => {
    const { images } = this.props;

    return images['main-card-acquisition-logo-image'].url;
  };

  getMainCardLogoAlt = () => {
    const { images } = this.props;

    return images['main-card-acquisition-logo-image'].alt;
  };

  getAcquisitionUrl = () => {
    const { links, customerChpras } = this.props;
    const { queryString } = this.state;

    let acquisitionLink = '';
    const isMidia = getQueryParam('midia');

    acquisitionLink = links['iframe-acquisition-url'].url;

    if (isMidia) {
      acquisitionLink = links['iframe-acquisition-midia-url'].url.concat(
        queryString
      );
    }
    acquisitionLink += `&${CHPRAS}=${customerChpras}`;
    return acquisitionLink;
  };

  render() {
    const { links /* , browser */ } = this.props;

    if (isObjEmpty(links)) {
      return (
        <div className="credicard-theme-default itau-loading-wrapper">
          <Loading loadPrimary={false} />
        </div>
      );
    }

    // if (browser === 'safari') {
    // * Ajuste para possibilitar o preenchimento do formulário no Safari
    if (this.getAcquisitionUrl()) {
      window.location.replace(this.getAcquisitionUrl());
    }

    return <div />;
    // }

    // return (
    //   <div className="credicard-theme-default card-acquisition-wrapper">
    //     <WebpageHeader
    //       logo={getImg(this.getMainCardLogo())}
    //       alt={this.getMainCardLogoAlt()}
    //     />

    //     <IFrame url={this.getAcquisitionUrl()} />
    //   </div>
    // );
  }
}

CardAcquisition.propTypes = {
  images: PropTypes.shape({
    'main-card-acquisition-logo-image': PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  links: PropTypes.shape({
    'iframe-acquisition-url': PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    'iframe-acquisition-midia-url': PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  // browser: PropTypes.string.isRequired,
  customerChpras: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  images: state.whiteLabel.images,
  links: state.whiteLabel.links,
  browser: state.app.browser,
  customerChpras: state.share.shareInfo.clientId,
});

const mapDispatchToProps = dispatch => bindActionCreators(AppActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CardAcquisition);
