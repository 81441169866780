import React from 'react';

import PropTypes from 'prop-types';
import './_Card.scss';

const Card = ({ children }) => (
  <div className="component-itaucard-wrapper">{children}</div>
);

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Card;
