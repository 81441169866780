const analyticsPage = {
  secao: 'MemberGetMember',
  subSecao1: 'PaginaDeMidiaWebView',
  url: 'www.indique.credicard.com.br',
  nome: 'CC:NL:NCC:MemberGetMember:PaginaDeMidiaWebView:CredicardZero',
  templateName: 'home',
};

const analyticsClick = 'BTN:NCC:MemberGetMember:MidiaIndicarAmigoWebView:CT:CP';

const analyticsCustom = {
  cartaoDn: 2063,
  cartaoVariante: 'Internacional',
  cartaoNome: 'Credicard Zero',
  cartaoBandeira: 'Mastercard',
  events: ['landingPage'],
};

const analyticsProduct = {
  items: [
    {
      merchandising: {
        'cdc:bandeira': 'Mastercard',
        'cdc:portfolio': 'CredicardZero',
        'cdc:rendaminima': 'R$0.000',
        'cdc:variante': 'Internacional',
      },
      nome: 'Credicard Zero',
      sku: '9004',
    },
  ],
  events: 'prodView',
};

export { analyticsPage, analyticsClick, analyticsCustom, analyticsProduct };
