export default {
  IMAGE_URL: 'cartao_credicard_zero.png',
  TITLE: 'Cartão Credicard ZERO',
  SUB_TITLE: 'Você usa, gosta e indica',
  CONTENT_LIST: [
    {
      ICON: 'icon-itaufonts_calendario',
      TITLE: '1ª anuidade',
      ALT_TITLE: 'ZERO',
    },
    {
      ICON: 'icon-itaufonts_calendario',
      TITLE: 'demais anuidades',
      ALT_TITLE: 'ZERO',
    },
  ],
};
