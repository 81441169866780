/**
 * Used to remove specific params from querystring
 *
 * @param {string[]} [removeParams = id] - Params to be removed from Querystring
 */

function getQueryParam(key) {
  const params = new URLSearchParams(window.location.search);

  return params.get(key);
}

export default function concatQuerystring(removeParams) {
  const pageUrl = window.location.href;
  const indexOfQueryString = pageUrl.indexOf('?');

  let queryString = '';

  if (indexOfQueryString >= 0) {
    const regxQuestionMark = /[?]/gi;

    queryString = pageUrl.substring(indexOfQueryString);
    queryString = queryString.replace(regxQuestionMark, '&');

    if (removeParams.length > 0) {
      for (let i = 0; i < removeParams.length; i += 1) {
        queryString = queryString.replace(
          `&${removeParams[i]}=${getQueryParam(removeParams[i])}`,
          ''
        );
      }
    }
  }

  return queryString;
}
