import React from 'react';

import PropTypes from 'prop-types';

import './_Title.scss';
import ActionButton from '../../../../components/ActionButton/ActionButton';

const idComponent = 'title';

const Title = ({
  idComponentContext,
  mainTitle,
  reference,
  propIndex,
  btnTitle,
  btnClick,
  isMobile,
}) => (
  <div
    className="titlewhitelabel-wrapper"
    id={`${idComponent}_${idComponentContext}`}
  >
    <div className="container">
      <h1
        className="main-title"
        id={`lblTitle_${idComponent}_${idComponentContext}`}
        ref={reference}
      >
        {mainTitle}
      </h1>
      {!isMobile && (
        <ActionButton
          idComponentContext={idComponentContext}
          index={propIndex}
          btnTitle={btnTitle}
          onClick={btnClick}
        />
      )}
    </div>
  </div>
);

Title.propTypes = {
  idComponentContext: PropTypes.string.isRequired,
  mainTitle: PropTypes.string.isRequired,
  reference: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  propIndex: PropTypes.number.isRequired,
  btnTitle: PropTypes.string.isRequired,
  btnClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

Title.defaultProps = {
  reference: () => {},
  isMobile: true,
};

export default Title;
