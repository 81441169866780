import { call, select, put } from 'redux-saga/effects';

import api from '../../config/api';
import environmentVariables from '../../config/environmentVariables';
import { getQueryParam } from '../../util/getQueryParam';
import normalizeDnsInfo from '../../util/normalizers/normalizeDnInfo';
import { Creators as AppActions } from '../ducks/app';
import { Creators as whitelabelActions } from '../ducks/whiteLabel';

export function* getWhiteLabel() {
  try {
    const { GATEWAY_APP_KEY, MGM_AUTH } = environmentVariables;
    const KEY = GATEWAY_APP_KEY;
    const token = MGM_AUTH;
    const config = { headers: { Authorization: `Basic ${token}` } };
    const dn = getQueryParam('dn');
    const { app, clientId } = yield select(state => state.share.shareInfo);

    if (dn === null || dn === '') {
      return yield put(
        AppActions.appFailure({
          type: 'dn-null-error',
          criticalError: true,
        })
      );
    }

    if (dn == null) {
      yield put(AppActions.disableRetry());
      return yield put(
        AppActions.appFailure({
          type: 'dn-type-error',
          criticalError: true,
        })
      );
    }

    const { data } = yield call(
      api.get,
      `/mgm-dn/v1/indicado/whitelabel?dn=${dn}&gw-app-key=${KEY}&id=${clientId}&app=${app}`,
      config
    );
    yield put(AppActions.setTheme(data.whiteLabel.cssTema));
    const normalizedDns = normalizeDnsInfo(data);
    yield put(whitelabelActions.setData(normalizedDns));
    return yield put(AppActions.clearErrorStatus());
  } catch (error) {
    const {
      response: { status, data },
    } = error;
    if (status === 302 && data.urlRedirect) {
      window.location.replace(data.urlRedirect);
      return yield put(AppActions.disabledGoogleChatAlert());
    }
    yield put(
      AppActions.appFailure({
        type: 'whitelabel-request-error',
        criticalError: true,
      })
    );
    throw new Error(error);
  }
}

export function* getWhiteLabelByCardCode() {
  try {
    const { GATEWAY_APP_KEY, MGM_AUTH } = environmentVariables;

    const CARD_CODE = getQueryParam('cartao');
    const KEY = GATEWAY_APP_KEY;
    const token = MGM_AUTH;
    const cardType = 'CREDICARD';

    const config = { headers: { Authorization: `Basic ${token}` } };

    if (CARD_CODE === null || CARD_CODE === '') {
      yield put(
        AppActions.appFailure({
          type: 'cardCode-null-error',
          criticalError: true,
        })
      );
      return;
    }
    if (CARD_CODE == null) {
      yield put(AppActions.disableRetry());
      yield put(
        AppActions.appFailure({
          type: 'cardCode-type-error',
          criticalError: true,
        })
      );
      return;
    }

    const response = yield call(
      api.get,
      `/mgm-dn/v1/indicado/whitelabel/${CARD_CODE}?cartaoTipo=${cardType}&gw-app-key=${KEY}`,
      config
    );

    yield put(AppActions.setTheme(response.data.whiteLabel.cssTema));
    const normalizedDns = normalizeDnsInfo(response.data);
    yield put(whitelabelActions.setData(normalizedDns));
    yield put(AppActions.clearErrorStatus());
  } catch (err) {
    yield put(
      AppActions.appFailure({
        type: 'whitelabel-request-error',
        criticalError: true,
      })
    );
    throw new Error(err);
  }
}
