/* eslint-disable import/no-dynamic-require */
import React from 'react';

import PropTypes from 'prop-types';

import ActionButton from '../../../../components/ActionButton/ActionButton';
import SimpleCarousel from '../../../../components/SimpleCarousel/SimpleCarousel';
import parseSanitizedHtml from '../../../../util/parseSanitizedHtml';
import ContentItemWeb from './ContentItemWeb/ContentItemWeb';
import './_BenefitsList.scss';

const getImg = img => {
  return require(`../../../../assets/carossel/${img}`);
};

const BenefitsList = ({
  listContent,
  idComponentContext,
  isMobile,
  titleContent,
  propIndex,
  btnTitle,
  btnClick,
}) => (
  <section
    id="beneficios"
    className="component-benefitslist-whitelabel-wrapper"
  >
    <div className="container">
      <h2 className="title-list">{titleContent}</h2>
      <SimpleCarousel idComponentContext={idComponentContext}>
        {listContent.map((item, index) => (
          <li className="content-item" key={item.title.text.trim()}>
            <ContentItemWeb
              key={`contentItem${item.title.text.trim()}`}
              idComponentContext={idComponentContext}
              index={index}
              title={item.title.text}
              imageUrl={getImg(item.image.url)}
              text={parseSanitizedHtml(item.description.text)}
              linkUrl={item.link.text !== '' ? item.link.url : ''}
              linkAria={item.link.text !== '' ? item.link.text : ''}
            />
          </li>
        ))}
      </SimpleCarousel>
      {!isMobile && (
        <ActionButton
          idComponentContext={idComponentContext}
          index={propIndex}
          btnTitle={btnTitle}
          onClick={btnClick}
          primaryBtn={false}
        />
      )}
    </div>
  </section>
);

BenefitsList.propTypes = {
  listContent: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.shape({
        text: PropTypes.string,
      }),
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
      link: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
      }),
      title: PropTypes.shape({
        text: PropTypes.string,
      }),
    })
  ),
  idComponentContext: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  titleContent: PropTypes.string,
  propIndex: PropTypes.number.isRequired,
  btnTitle: PropTypes.string.isRequired,
  btnClick: PropTypes.func.isRequired,
};

BenefitsList.defaultProps = {
  listContent: [],
  isMobile: false,
  titleContent: '',
};

export default BenefitsList;
