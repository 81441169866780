import React, { useEffect } from 'react';

import { google, facebook, pageLoad, customLink } from '../../analytics';
import ActionButton from '../../components/ActionButton/ActionButton';
import constants from '../../constants';
import {
  analyticsPage,
  analyticsClick,
  analyticsCustom,
  analyticsProduct,
} from './MidiaPageAnalytics';

import './styles.scss';

const idComponentContext = 'pageHome';
const {
  CAMPAIGNS: {
    MIDIA: {
      BUTTON: { BUTTON_TITLE, BUTTON_LINK },
    },
  },
} = constants;

export default function MidiaPage() {
  function handleMount() {
    google.pageLoad();
    facebook.pageLoad();

    pageLoad({
      page: analyticsPage,
      custom: analyticsCustom,
      product: analyticsProduct,
    });
  }

  useEffect(() => {
    handleMount();
  });

  function handleButton() {
    google.customClick();
    facebook.customClick();

    customLink(analyticsClick);

    const pageUrl = window.location.href;
    const indexOfQueryString = pageUrl.indexOf('?');
    let queryString = '';

    if (indexOfQueryString >= 0) {
      const regxQuestionMark = /[?]/gi;

      queryString = pageUrl.substring(indexOfQueryString);
      queryString = queryString.replace(regxQuestionMark, '&');
      queryString = encodeURIComponent(queryString);
      queryString = '?midia=true'.concat(queryString);
    }

    return window.location.replace(BUTTON_LINK.concat(queryString));
  }

  return (
    <div className="pageHome">
      <div className="itau-global-header">
        <h1 id={`lblHeader_${idComponentContext}`} className="title">
          indicar amigos
        </h1>
      </div>

      <div id="container" className="home-wrapper">
        <div className="content">
          <h2 id={`lblTitle_${idComponentContext}`}>
            Você ainda tem amigos que pagam
            <strong> anuidade do cartão?</strong>
          </h2>

          <p id={`lblContent_${idComponentContext}`}>
            Indique o Credicard ZERO, o cartão que tem ZERO anuidade, e muitos
            benefícios. <strong>Compartilhe!</strong>
          </p>
        </div>

        <div className="button-item">
          <ActionButton
            btnTitle={BUTTON_TITLE}
            idComponentContext={idComponentContext}
            index={0}
            onClick={handleButton}
          />
        </div>
      </div>
    </div>
  );
}
