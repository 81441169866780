/**
 * Returns the first name camel case  of given full name (or simple name)
 * @param {*} name - a name
 */
export const normalizeName = name => {
  if (name) {
    let firstName = name.split(' ')[0];

    if (firstName && firstName.length > 1) {
      firstName =
        firstName[0].toUpperCase() +
        firstName.substring(1, firstName.length).toLowerCase();
      return firstName;
    }
  }

  return '';
};
