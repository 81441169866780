import React from 'react';

import PropTypes from 'prop-types';

import ActionButton from '../../../../components/ActionButton/ActionButton';
import DescriptionItem from '../../../../components/DescriptionItem/DescriptionItem';
import './_PageIntro.scss';

const idComponent = 'pageIntro';
const PageIntro = ({
  idComponentContext,
  introTitle,
  introSubTitle,
  introImage,
  introImageAlt,
  introDescriptions,
  propIndex,
  btnTitle,
  btnClick,
}) => (
  <section id="cartao" className="pageintrowhitelabel-wrapper">
    <div className="container" id={`${idComponent}_${idComponentContext}`}>
      <img
        className="intro-image"
        src={introImage}
        alt={introImageAlt}
        aria-hidden
      />

      <div className="intro-content">
        <div className="intro-header">
          <h2
            className="intro-title"
            id={`lblTitle_${idComponent}_${idComponentContext}`}
          >
            {introTitle}
          </h2>
          <p className="intro-subtitle"> {introSubTitle} </p>
        </div>

        <ul className="intro-descriptions">
          {/*
          // TODO Ajustar renderização abaixo no itaucard
          */}
          {introDescriptions &&
            introDescriptions.map((contentItem, index) => {
              return contentItem.detailtitle.text !== '' ? (
                <li
                  key={contentItem.detailtitle.text}
                  className="description-item"
                  id={`lstItem${index}_${idComponent}_${idComponentContext}`}
                >
                  <DescriptionItem
                    icon={contentItem.icon.name}
                    title={contentItem.detailtitle.text}
                    altTitle={
                      contentItem.detailvalue
                        ? contentItem.detailvalue.text
                        : ''
                    }
                  />
                </li>
              ) : (
                <div aria-hidden />
              );
            })}
        </ul>
        <ActionButton
          idComponentContext={idComponentContext}
          index={propIndex}
          btnTitle={btnTitle}
          onClick={btnClick}
        />
      </div>
    </div>
  </section>
);

PageIntro.propTypes = {
  idComponentContext: PropTypes.string.isRequired,
  introTitle: PropTypes.string.isRequired,
  introSubTitle: PropTypes.string.isRequired,
  introImage: PropTypes.string.isRequired,
  introDescriptions: PropTypes.arrayOf(
    PropTypes.shape({
      // TODO: Definir estes Proptypes
    })
  ).isRequired,
  introImageAlt: PropTypes.string,
  propIndex: PropTypes.number.isRequired,
  btnTitle: PropTypes.string.isRequired,
  btnClick: PropTypes.func.isRequired,
};

PageIntro.defaultProps = {
  introImageAlt: '',
};

export default PageIntro;
