import React, { Component } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import environment from '../../config/environmentVariables';
import { sendMessage } from '../../endpoints/googleChatMessage';
import DefaultError from '../../pages/DefaultError/DefaultError';
import setLocalPathnameUrl from '../../routes/setLocalPathnameUrl';
import store from '../../store';
import { Creators as AppActions } from '../../store/ducks/app';
import { getQueryParam } from '../../util/getQueryParam';
import App from '../App';

export class AppCardCode extends Component {
  constructor() {
    super();
    this.state = {
      hasValidParam: this.hasValidUrlParam(),
    };
  }

  componentDidMount() {
    const { GOOGLE_CHAT_API_INVALID_URL_PARAMS } = environment;
    const { hasValidParam } = this.state;

    setLocalPathnameUrl(
      window.location.pathname.concat(window.location.search)
    );

    if (hasValidParam) {
      store.dispatch(AppActions.appInitByCardCode());
    } else {
      sendMessage(
        new Error('Os parâmetros da URL estão inválidos'),
        'Fluxo de CardCode',
        GOOGLE_CHAT_API_INVALID_URL_PARAMS
      );
    }
  }

  hasValidUrlParam = () => {
    const cardNumberParamValue = getQueryParam('cartao');
    return cardNumberParamValue && cardNumberParamValue !== 'null';
  };

  render() {
    const { hasValidParam } = this.state;
    if (hasValidParam) {
      return <App />;
    }
    return <DefaultError removeButton retryAction={false} />;
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators(AppActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppCardCode);
