import React from 'react';

import PropTypes from 'prop-types';
import './_ActionButton.scss';

const ActionButton = ({
  isDisabled,
  notActive,
  btnTitle,
  idComponentContext,
  index,
  primaryBtn,
  onClick,
  dataClipBoardText,
}) => (
  <button
    disabled={isDisabled || notActive}
    id={`btnAction_${idComponentContext}_${index}`}
    type="submit"
    className={`itau-action-button ${
      primaryBtn ? 'itau-primary-button' : 'itau-secondary-button'
    }${notActive ? '-not-active' : ''}`}
    onClick={onClick}
    data-clipboard-text={dataClipBoardText}
  >
    {btnTitle}
  </button>
);

ActionButton.propTypes = {
  idComponentContext: PropTypes.string.isRequired,
  index: PropTypes.number,
  btnTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  primaryBtn: PropTypes.bool,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  notActive: PropTypes.bool,
  dataClipBoardText: PropTypes.string,
};

ActionButton.defaultProps = {
  index: 0,
  btnTitle: 'ActionButton',
  primaryBtn: true,
  onClick: () => {},
  isDisabled: false,
  notActive: false,
  dataClipBoardText: '',
};

export default ActionButton;
