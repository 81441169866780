import { call, put } from 'redux-saga/effects';

import api from '../../config/api';
import environmentVariables from '../../config/environmentVariables';
import { getQueryParam } from '../../util/getQueryParam';
import { Creators as AppActions } from '../ducks/app';
import { Creators as ShareActions } from '../ducks/share';

export function* getShare() {
  try {
    const { GATEWAY_APP_KEY, MGM_AUTH } = environmentVariables;
    const CHPRAS = getQueryParam('id');
    const KEY = GATEWAY_APP_KEY;
    const token = MGM_AUTH;

    const config = { headers: { Authorization: `Basic ${token}` } };

    if (CHPRAS === null || CHPRAS === '') {
      return yield put(
        AppActions.appFailure({
          type: 'chpras-null-error',
          criticalError: true,
        })
      );
    }
    const response = yield call(
      api.get,
      `/mgm/v2/cliente?gw-app-key=${KEY}&token=${CHPRAS}`,
      config
    );

    yield put(ShareActions.getShareSuccess(response.data));
    return yield put(AppActions.clearErrorStatus());
  } catch (err) {
    yield put(
      AppActions.appFailure({
        type: 'share-request-error',
        criticalError: true,
      })
    );
    throw new Error(err);
  }
}
