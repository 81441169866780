const analyticsPage = {
  secao: 'MemberGetMember',
  subSecao1: 'CartaoIndicadoPorAmigo',
  url: 'www.indique.credicard.com.br',
  nome: 'CC:NL:NCC:MemberGetMember:CartaoIndicadoPorAmigo:Credicard',
  templateName: 'home',
};

const analyticsClick = 'BTN:NCC:MemberGetMember:PedirCartao:CT:CP';

export { analyticsPage, analyticsClick };
