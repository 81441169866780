import track from '../track';

export default function customLinkCredicard(itemClicado) {
  const analytics = {
    cartao: { nome: 'Credicard' },
    custom: {
      itemClicado,
      events: ['cliqueEmBotao'],
    },
    rule: 'customLink',
  };

  track(analytics);
}
