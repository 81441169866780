import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import _ from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { customLink, pageLoad } from '../../analytics';
import ActionButton from '../../components/ActionButton/ActionButton';
import Card from '../../components/Card/Card';
import NavMenu from '../../components/NavMenu/NavMenu';
import WebpageHeader from '../../components/WebpageHeader/WebpageHeader';
import constants from '../../constants';
import { Creators as AppActions } from '../../store/ducks/app';
import { Creators as RoutesControlActions } from '../../store/ducks/routesControl';
import { Creators as ShareInfoActions } from '../../store/ducks/share';
import { Creators as WhiteLabelActions } from '../../store/ducks/whiteLabel';
import DefaultTemplate from '../../template/DefaultTemplate';
import whiteLabelContentsObjectStructurer from '../../util/whiteLabelContentsObjectStructurer';
import BenefitsList from './components/BenefitsList/BenefitsList';
import Depoiments from './components/Depoiments/Depoiments';
import { DynamicBackground } from './components/DynamicBackground/DynamicBackground';
import Footer from './components/Footer/Footer';
import PageIntro from './components/PageIntro/PageIntro';
import ProductDetailList from './components/ProductDetailList/ProductDetailList';
import Title from './components/Title/Title';
import { analyticsPage, analyticsClick } from './HomeAnalytics';

import './_Home.scss';

const idComponentContext = 'pageHome';
const observerOptions = {
  rootMargin: '-100px 0px 190px 0px',
  threshold: {
    mainTitleSemiVisible: 0.96,
    mainTitleFullyVisible: 0.97,
  },
};
const navMenuParams = {
  filter: /navmenu-landing/,
  contentTypes: ['links'],
};

const pageIntroParams = {
  filter: /pageintro-landing/,
  contentTypes: ['texts', 'images', 'icons'],
};

const benefitsListParams = {
  filter: /benefititem-landing/,
  contentTypes: ['texts', 'images', 'links'],
};

const cardDetailParams = {
  filter: /carddetailitem-landing/,
  contentTypes: ['texts', 'links', 'icons'],
};

const socialMediaComentaryListParams = {
  filter: /socialmediacommentaryitem-landing/,
  contentTypes: ['texts', 'images'],
};

const aboutUsParams = {
  filter: /aboutus-lading/,
  contentTypes: ['links'],
};

const getImg = img => {
  return require(`../../assets/${img}`);
};

const getBackgroundImg = (img, isMobile) => {
  if (isMobile) {
    return img.urlMobile
      ? require(`../../assets/background/${img.urlMobile}`)
      : null;
  }
  return require(`../../assets/background/${img.url}`);
};

const {
  ROUTES: { CARD_ACQUISITION },
  SCREEN_SIZES,
} = constants;

class Home extends PureComponent {
  handleResize = _.throttle(() => {
    this.setState({ isMobile: window.innerWidth < SCREEN_SIZES.TABLET_SMALL });
  }, 100);

  constructor(props) {
    const {
      rootMargin,
      threshold: { mainTitleSemiVisible, mainTitleFullyVisible },
    } = observerOptions;

    super(props);
    this.navMenu = React.createRef();
    this.titleArea = React.createRef();
    this.mainTitle = React.createRef();

    this.observer = new IntersectionObserver(this.setHeaderNegativeStyling, {
      rootMargin,
      threshold: [mainTitleSemiVisible, mainTitleFullyVisible],
    });

    this.state = {
      isMobile: window.innerWidth < SCREEN_SIZES.TABLET_SMALL,
      scrolled: false,
    };
  }

  componentDidMount() {
    const { changePermition, analyticsCustom, analyticsProduct } = this.props;

    this.setState({ isMobile: window.innerWidth < SCREEN_SIZES.TABLET_SMALL });
    window.addEventListener('resize', this.handleResize);
    this.createObserver(this.mainTitle);

    pageLoad({
      page: analyticsPage,
      product: analyticsProduct,
      custom: analyticsCustom,
    });

    changePermition({
      route: 'acquisition',
      permition: true,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getCardAcquisitionPagePath = () => {
    return CARD_ACQUISITION;
  };

  createObserver = observedSection => {
    this.observer.observe(observedSection.current);
  };

  setHeaderNegativeStyling = entries => {
    const {
      threshold: { mainTitleSemiVisible, mainTitleFullyVisible },
    } = observerOptions;

    entries.forEach(entry => {
      if (entry.intersectionRatio <= mainTitleSemiVisible) {
        if (this.navMenu.current) {
          this.navMenu.current.classList.add('scrolled');
          this.setState({ scrolled: true });
        }
      } else if (entry.intersectionRatio >= mainTitleFullyVisible) {
        if (this.navMenu.current) {
          this.navMenu.current.classList.remove('scrolled');
          this.setState({ scrolled: false });
        }
      }
    });
  };

  mainButton = btnTitle => (
    <ActionButton
      idComponentContext={idComponentContext}
      btnTitle={btnTitle}
      onClick={this.handleButton}
    />
  );

  replaceMainTitle = (title, name) => {
    if (name === '') name = 'o seu amigo';

    // eslint-disable-next-line no-template-curly-in-string
    return title.replace('${customer.name}', `${name}`);
  };

  getContentArray = (content, params) => {
    const { filter, contentTypes } = params;
    return whiteLabelContentsObjectStructurer(content, filter, contentTypes);
  };

  handleButton = () => {
    const { history } = this.props;

    customLink(analyticsClick);

    history.push(this.getCardAcquisitionPagePath());
  };

  handleReference = ref => {
    switch (ref) {
      case 'cartao':
        return '#cartao';
      case 'beneficios':
        return '#beneficios';
      case 'transparencia':
        return '#transparencia';
      case 'quem indica':
        return '#quem-indica';
      default:
        return '#';
    }
  };

  render() {
    const {
      whitelabelContent,
      images,
      texts,
      links,
      customerName,
    } = this.props;
    const { isMobile, scrolled } = this.state;

    return (
      <div className="home-wrapper-whitelabel">
        <div className="home-scroll">
          <DynamicBackground
            className="dynamic-background-component"
            hasCompositionElement={
              !images['main-background-landing-composition-element'].hidden
            }
            backgroundColor={
              images['main-background-landing-image'].backgroundColor
            }
            imageSrc={getBackgroundImg(
              images['main-background-landing-image'],
              isMobile
            )}
          >
            <DefaultTemplate>
              <NavMenu
                idComponentContext={idComponentContext}
                navLogo={getImg(
                  images['main-application-logo-positive-image'].url
                )}
                navLogoNegative={getImg(
                  images['main-application-logo-negative-image'].url
                )}
                navLogoAlt={images['main-application-logo-positive-image'].alt}
                scrolled={scrolled}
                navLinks={this.getContentArray(
                  whitelabelContent,
                  navMenuParams
                )}
                componentRef={this.navMenu}
                reference={e => this.handleReference(e)}
              />

              <header role="banner">
                <WebpageHeader
                  idComponentContext={idComponentContext}
                  alt={images['main-application-logo-positive-image'].alt}
                  logo={getImg(
                    images['main-application-logo-positive-image'].url
                  )}
                />

                <div ref={this.titleArea}>
                  <Title
                    reference={this.mainTitle}
                    idComponentContext={idComponentContext}
                    mainTitle={this.replaceMainTitle(
                      texts['main-landing-title'].text,
                      customerName
                    )}
                    btnTitle={links['main-landing-button-acquistion'].text}
                    btnClick={this.handleButton}
                    propIndex={0}
                    isMobile={isMobile}
                  />
                </div>
              </header>
              <Card>
                <main role="main">
                  <PageIntro
                    idComponentContext={idComponentContext}
                    introTitle={texts['pageintro-landing-title'].text}
                    introSubTitle={texts['pageintro-landing-subtitle'].text}
                    introImage={require(`../../assets/cartao/${images['main-landing-card-image'].url}`)}
                    introDescriptions={this.getContentArray(
                      whitelabelContent,
                      pageIntroParams
                    )}
                    btnTitle={links['main-landing-button-acquistion'].text}
                    btnClick={this.handleButton}
                    propIndex={1}
                  />

                  <BenefitsList
                    idComponentContext={idComponentContext}
                    titleContent={texts['benefitslist-landing-title'].text}
                    isMobile={isMobile}
                    listContent={this.getContentArray(
                      whitelabelContent,
                      benefitsListParams
                    )}
                    btnTitle={links['main-landing-button-acquistion'].text}
                    btnClick={this.handleButton}
                    propIndex={2}
                  />

                  <ProductDetailList
                    idComponentContext={idComponentContext}
                    listTitle={texts['carddetaillist-landing-title'].text}
                    listContent={this.getContentArray(
                      whitelabelContent,
                      cardDetailParams
                    )}
                  />

                  {texts['socialmediacommentarylist-landing-title'].text && (
                    <Depoiments
                      idComponentContext={idComponentContext}
                      listTitle={
                        texts['socialmediacommentarylist-landing-title'].text
                      }
                      listContent={this.getContentArray(
                        whitelabelContent,
                        socialMediaComentaryListParams
                      )}
                    />
                  )}
                </main>

                <Footer
                  idComponentContext={idComponentContext}
                  aboutUsTitle={texts['aboutus-landing-title'].text}
                  companyName={texts['aboutus-landing-company-name'].text}
                  companyAddress={texts['aboutus-landing-company-address'].text}
                  aboutUsLinks={this.getContentArray(
                    whitelabelContent,
                    aboutUsParams
                  )}
                />
              </Card>
            </DefaultTemplate>
          </DynamicBackground>
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  changePermition: PropTypes.func.isRequired,
  analyticsCustom: PropTypes.shape().isRequired,
  analyticsProduct: PropTypes.shape().isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  customerName: PropTypes.string.isRequired,
  whitelabelContent: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  images: PropTypes.shape({
    'main-background-landing-composition-element': PropTypes.shape({
      hidden: PropTypes.bool,
    }).isRequired,
    'main-background-landing-image': PropTypes.shape({
      url: PropTypes.string,
      urlMobile: PropTypes.string,
      alt: PropTypes.string,
      backgroundColor: PropTypes.string,
    }).isRequired,
    'main-application-logo-positive-image': PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }).isRequired,
    'main-application-logo-negative-image': PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }).isRequired,
    'main-landing-card-image': PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }).isRequired,
  }).isRequired,
  texts: PropTypes.shape({
    'main-landing-title': PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
    'pageintro-landing-subtitle': PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
    'pageintro-landing-title': PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
    'benefitslist-landing-title': PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
    'carddetaillist-landing-title': PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
    'socialmediacommentarylist-landing-title': PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
    'aboutus-landing-title': PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
    'aboutus-landing-company-name': PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
    'aboutus-landing-company-address': PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
  }).isRequired,
  links: PropTypes.shape({
    'main-landing-button-acquistion': PropTypes.shape({
      url: PropTypes.string,
      text: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  analyticsProduct: state.analytics.product,
  analyticsCustom: state.analytics.custom,
  customerChpras: state.share.shareInfo.clientId,
  customerName: state.share.shareInfo.firstName,
  cardDn: state.whiteLabel.dn,
  whitelabelContent: state.whiteLabel,
  images: state.whiteLabel.images,
  texts: state.whiteLabel.texts,
  links: state.whiteLabel.links,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...AppActions,
      ...WhiteLabelActions,
      ...RoutesControlActions,
      ...ShareInfoActions,
    },
    dispatch
  );

const HomeWithRouter = withRouter(Home);

export default connect(mapStateToProps, mapDispatchToProps)(HomeWithRouter);
