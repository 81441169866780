import React from 'react';

import PropTypes from 'prop-types';
import './_DefaultTemplate.scss';

const DefaultTemplate = ({ children, className }) => (
  <div className={`default-template-whitelabel ${className}`}>{children}</div>
);

DefaultTemplate.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

DefaultTemplate.defaultProps = {
  className: '',
};

export default DefaultTemplate;
