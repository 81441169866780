import cardCarossel1 from '../../assets/carossel/benefititem-landing-image-zero-1.jpg';
import cardCarossel3 from '../../assets/carossel/benefititem-landing-image-zero-3.jpg';
import cardCarossel4 from '../../assets/carossel/benefititem-landing-image-zero-4.jpg';

export default {
  HOME: {
    TITLE: 'Seu amigo também pode zerar o melhor da vida',
  },
  BENEFITS_LIST: {
    TITLE: 'Benefícios',
    CONTENT: [
      {
        TITLE: 'indicação',
        IMAGE_URL: cardCarossel1,
        CONTENT: 'Indique o Credicard ZERO para seus amigos.',
      },
      {
        TITLE: 'pedido do cartão',
        IMAGE_URL: cardCarossel4,
        CONTENT: 'Eles preenchem o pedido do cartão pelo link que você enviou.',
      },
      {
        TITLE: 'aprovação',
        IMAGE_URL: cardCarossel3,
        CONTENT: 'Com a aprovação, seu amigo pode zerar o melhor da vida!',
      },
    ],
  },
  BUTTON: {
    BUTTON_AREA_TITLE: 'Compartilhe:',
    BUTTON_TITLE: 'WhatsApp',
    BUTTON_LINK:
      'https://api.whatsapp.com/send?text=E%20aí,%20blz?%20Acabei%20de%20te%20indicar%20para%20pedir%20o%20Cartão%20Credicard%20ZERO,%20o%20cartão%20zero%20anuidade%20e%20cheio%20de%20benefícios.%20Clique%20e%20peça%20já%20o%20seu!%20https://www.indique.credicard.com.br/',
  },
};
