/* eslint-disable func-names */
const google = {
  pageLoad: () => {
    window.gtag('event', 'conversion', {
      send_to: 'AW-723007323/qeYfCILpjrQBENvu4NgC',
    });
  },

  customClick: url => {
    const callback = function() {
      if (typeof url !== 'undefined') {
        window.location = url;
      }
    };

    window.gtag('event', 'conversion', {
      send_to: 'AW-723007323/frJMCIb2jrQBENvu4NgC',
      event_callback: callback,
    });

    return false;
  },
};

export default google;
