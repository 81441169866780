import React from 'react';

import PropTypes from 'prop-types';
import './_DepoimentItem.scss';

const DepoimentItem = ({
  socialMedia,
  profilePic,
  name,
  nickname,
  depoiment,
  time,
  date,
}) => (
  <div className="component-depoiment-wrapper">
    <h3 className="socialMediaTitle">{socialMedia}</h3>
    <div className="profile">
      <img className="profilePic" src={profilePic} alt="" />
      <div className="profileDetail">
        <p className="name">{name}</p>
        {nickname && <p className="nickname">{nickname}</p>}
      </div>
    </div>
    <p className="depoiment-text">{depoiment}</p>
    {time !== '' && date !== '' && (
      <p className="dateTime">{`${time} - ${date}`}</p>
    )}
  </div>
);

DepoimentItem.propTypes = {
  socialMedia: PropTypes.string.isRequired,
  profilePic: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nickname: PropTypes.string.isRequired,
  depoiment: PropTypes.string.isRequired,
  time: PropTypes.string,
  date: PropTypes.string,
};

DepoimentItem.defaultProps = {
  time: '',
  date: '',
};

export default DepoimentItem;
