const facebook = {
  pageLoad: () => {
    window.fbq('track', 'PageView');
  },

  customClick: () => {
    window.fbq('track', 'Purchase', { content_name: 'b-mgm-whatsapp' });
  },
};

export default facebook;
