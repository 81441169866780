import environmentVariables from '../config/environmentVariables';
import { sendMessage } from '../endpoints/googleChatMessage';
import store from '../store';
import { Creators as AppActions } from '../store/ducks/app';

const { appFailure } = AppActions;

const {
  //  DEBUG_MODE,
  //  TURN_GOOGLE_ANALYTICS_ON,
  GOOGLE_CHAT_API_URL,
} = environmentVariables;

// const isGoogleAnalyticsOn = TURN_GOOGLE_ANALYTICS_ON;
// const isDebugModeOn = DEBUG_MODE;

export default function track(data) {
  try {
    // eslint-disable-next-line no-console
    //    if (isDebugModeOn) console.log(data.rule, data);

    window.analyticsData = data;

    //    if (isGoogleAnalyticsOn) window._frameworkDA.Track();
  } catch (error) {
    store.dispatch(appFailure({ type: 'itau-tracking', criticalError: false }));
    sendMessage(error, 'Track Analytics', GOOGLE_CHAT_API_URL);
  }
}
