import { combineReducers } from 'redux';

import analytics from './analytics';
import app from './app';
import routesControl from './routesControl';
import share from './share';
import whiteLabel from './whiteLabel';

export default combineReducers({
  app,
  share,
  whiteLabel,
  routesControl,
  analytics,
});
