import { all, takeLatest } from 'redux-saga/effects';

import { Types as appTypesWhiteLabel } from '../ducks/app';
import { Types as whiteLabelTypes } from '../ducks/whiteLabel';
import { appInit, appInitByCardCode } from './app';
import { getWhiteLabel } from './whiteLabel';

export default function* rootSaga() {
  yield all([
    takeLatest(appTypesWhiteLabel.APP_INIT, appInit),
    takeLatest(appTypesWhiteLabel.APP_INIT_BY_CARDCODE, appInitByCardCode),
    takeLatest(whiteLabelTypes.WHITE_LABEL_REQUEST, getWhiteLabel),
  ]);
}
