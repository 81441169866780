import CAMPAIGNS from './CAMPAIGNS';
import QUERY_PARAMS from './QUERY_PARAMS';
import RESOURCES from './RESOURCES';
import ROUTES from './ROUTES';
import SCREEN_SIZES from './SCREEN_SIZES';
import SENTRY_CONFIG from './SENTRY_CONFIG';

const constants = {
  CAMPAIGNS,
  QUERY_PARAMS,
  RESOURCES,
  ROUTES,
  SCREEN_SIZES,
  SENTRY_CONFIG,
};

export default constants;
