/**
 * Used to get the query parameter of the specified key by
 * getting the location.search value of the window
 *
 * @param {string} key
 */

const getQueryParam = key => {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
};

export { getQueryParam };
