import environmentVariables from './config/environmentVariables';

const head = document.getElementsByTagName('head')[0];

const scriptZupDataLayerConfig = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'UA-161576556-1',
    {
      'optimize_id': 'GTM-KGV4PNF',
      'custom_map': {
        'dimension1': 'dn',
        'dimension2': 'id_cartao',
        'dimension3': 'bandeira',
        'dimension4': 'ambiente',
        'dimension5': 'fluxo',
        'dimension6': 'tela',
        'dimension7': 'marca_cartao',
        'dimension8': 'dn_equivalente',
        'dimension9': 'aplicacao'
      }
    }
  );
`;
const scriptAntiFlicker = `
  (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
  h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
  (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
  })(window,document.documentElement,'async-hide','dataLayer',4000,
  {'GTM-KGV4PNF':true});
`;
const styleAntiFlicker = `
  .async-hide { opacity: 0 !important}
`;

const configure_optimize = async () => {
  const elementZupDataLayerConfig = document.createElement('script');
  const elementAntiFlickerStyle = document.createElement('style');
  const elementAntiFlickerScript = document.createElement('script');

  elementZupDataLayerConfig.innerHTML = scriptZupDataLayerConfig;
  elementZupDataLayerConfig.type = 'text/javascript';

  elementAntiFlickerScript.innerHTML = scriptAntiFlicker;
  elementAntiFlickerScript.type = 'text/javascript';

  elementAntiFlickerStyle.innerHTML = styleAntiFlicker;
  elementAntiFlickerStyle.type = 'text/css';

  await head.appendChild(elementZupDataLayerConfig);
  await head.appendChild(elementAntiFlickerScript);
  await head.appendChild(elementAntiFlickerStyle);
};

const configure = async () => {
  const { HAS_EXPERIMENTS } = environmentVariables;
  if (HAS_EXPERIMENTS) await configure_optimize();
};

export default configure;
