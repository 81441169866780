import React from 'react';

import PropTypes from 'prop-types';

import DepoimentItem from './components/DepoimentItem/DepoimentItem';
import './_Depoiments.scss';

const Depoiments = ({ listContent, listTitle, idComponentContext }) => (
  <section id="quem-indica" className="depoiments-section">
    <div className="container">
      <h2 className="title" id={`lblTitle2_${idComponentContext}_2`}>
        {listTitle}
      </h2>
      <ul className="content">
        {listContent.map(item => (
          <li className="depoiment" key={item.profilename.text}>
            <DepoimentItem
              socialMedia={item.platform.text}
              profilePic={require(`../../../../assets/depoiments/${item.image.url}`)}
              name={item.profilename.text}
              nickname={item.username.text}
              depoiment={item.commentary.text}
              time={item.time}
              date={item.date}
            />
          </li>
        ))}
      </ul>
    </div>
  </section>
);

Depoiments.propTypes = {
  listContent: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.shape({
        text: PropTypes.string,
      }),
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
      link: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
      }),
      title: PropTypes.shape({
        text: PropTypes.string,
      }),
    })
  ),
  idComponentContext: PropTypes.string,
  listTitle: PropTypes.string,
};

Depoiments.defaultProps = {
  listContent: [],
  listTitle: '',
  idComponentContext: '',
};

export default Depoiments;
