import React from 'react';

import { PropTypes } from 'prop-types';
import styled from 'styled-components';

import CompositionElementLine from './CompositionElementLine/CompositionElementLine';

import './_DynamicBackground.scss';

const Wrapper = styled.div`
  background-image: url('${props => props.imageSrc}');
  background-color: ${props => props.backgroundColor};
`;

export function DynamicBackground({
  hasCompositionElement,
  imageSrc,
  backgroundColor,
  children,
}) {
  return (
    <Wrapper
      imageSrc={imageSrc}
      backgroundColor={backgroundColor}
      className="dynamic-background-component"
    >
      {hasCompositionElement && <CompositionElementLine />}
      {children}
    </Wrapper>
  );
}

DynamicBackground.propTypes = {
  hasCompositionElement: PropTypes.bool.isRequired,
  imageSrc: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
