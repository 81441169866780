import React from 'react';

import PropTypes from 'prop-types';
import './_Footer.scss';

const Footer = ({
  idComponentContext,
  aboutUsLinks,
  aboutUsTitle,
  companyName,
  companyAddress,
}) => (
  <footer className="footer">
    <div className="container">
      <div className="titleBox">
        <h2 className="title" id={`lblTitle2_${idComponentContext}_3`}>
          {aboutUsTitle}
        </h2>
      </div>
      <ul className="content-list">
        {aboutUsLinks &&
          aboutUsLinks.map((contentItem, index) => (
            <li key={contentItem.link.url} className="content-item">
              <a
                id={`lnkNav_${idComponentContext}_Footer${index}`}
                className="link"
                href={contentItem.link.url}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={contentItem.link.ariaLabel}
              >
                {contentItem.link.text}
              </a>
            </li>
          ))}
      </ul>
      <div className="addressBox">
        <p className="address">{companyName}</p>
        <p className="address">{companyAddress}</p>
      </div>
      <div className="lastLine" aria-hidden />
    </div>
  </footer>
);

Footer.propTypes = {
  idComponentContext: PropTypes.string.isRequired,
  aboutUsLinks: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
        ariaLabel: PropTypes.string,
      }),
    })
  ),
  aboutUsTitle: PropTypes.string,
  companyName: PropTypes.string,
  companyAddress: PropTypes.string,
};

Footer.defaultProps = {
  aboutUsLinks: [],
  aboutUsTitle: PropTypes.string,
  companyName: PropTypes.string,
  companyAddress: PropTypes.string,
};

export default Footer;
