import React from 'react';

import PropTypes from 'prop-types';

import ProductDetailItem from './ProductDetailItem/ProductDetailItem';
import './_ProductDetailList.scss';

const idComponent = 'productDetailList';

const ProductDetailList = ({ idComponentContext, listTitle, listContent }) => (
  <section id="transparencia" className="productdetailwhitelabel-list-wrapper">
    <div className="container" id={`${idComponent}_${idComponentContext}`}>
      <h2
        className="list-title"
        id={`lblTitle_${idComponent}_${idComponentContext}`}
      >
        {listTitle}
      </h2>

      <ul className="content-list">
        {listContent &&
          listContent.map((contentItem, index) => (
            <li
              key={contentItem.description.text}
              className="content-item"
              id={`lstItem${index}_${idComponent}_${idComponentContext}`}
            >
              <ProductDetailItem
                idComponentContext={idComponentContext}
                index={index}
                itemIcon={contentItem.icon.name}
                itemTitle={contentItem.title.text}
                itemText={contentItem.description.text}
                itemLink={contentItem.link.text ? contentItem.link.text : ''}
                itemUrl={contentItem.link.text ? contentItem.link.url : ''}
                itemAriaLabel={contentItem.link.ariaLabel}
              />
            </li>
          ))}
      </ul>
    </div>
  </section>
);

ProductDetailList.propTypes = {
  listContent: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.shape({
        text: PropTypes.string,
      }),
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
      link: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
      }),
      title: PropTypes.shape({
        text: PropTypes.string,
      }),
    })
  ),
  idComponentContext: PropTypes.string.isRequired,
  listTitle: PropTypes.string.isRequired,
};

ProductDetailList.defaultProps = {
  listContent: [],
};

export default ProductDetailList;
