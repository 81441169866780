import track from '../track';

export default function pageLoadCredicard({ page, product, custom }) {
  const analytics = {
    site: {
      nome: 'CC',
      ambiente: 'NL',
      negocio: 'NCC',
      tipoDeCanal: 'Web',
    },
    cartao: { nome: 'Credicard' },
    page,
    custom,
    rule: 'pageLoad',
  };

  if (product && product.items.length > 0) analytics.product = product;

  track(analytics);
}
