/**
 * * Types
 */
export const Types = {
  APP_INIT: 'app/init',
  APP_INIT_BY_CARDCODE: 'app/initByCardCode',
  APP_FINISH_LOADING: 'app/finishLoading',
  APP_FAILURE: 'app/failure',
  APP_RETRY_INCREMENT: 'app/RETRY_INCREMENT',
  DISABLE_RETRY: 'app/disableRetry',
  CLEAR_ERROR_STATUS: 'app/clearErrorStatus',
  SET_THEME: 'app/setTheme',
  SET_BROWSER: 'app/SET_BROWSER',
  DISABLE_GOOGLE_CHAT_ALERT: 'app/DISABLE_GOOGLE_CHAT_ALERT',
};

/**
 * * Reducer
 */
export const INITIAL_STATE = {
  loading: true,
  theme: '',
  browser: '',
  shouldDispatchAlert: true,
  error: {
    criticalError: false,
    hasError: false,
    type: '',
    retryCount: 0,
    retryDisabled: false,
    autoRetry: true,
  },
};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.APP_INIT:
      return {
        ...state,
        loading: true,
      };

    case Types.APP_INIT_BY_CARDCODE:
      return {
        ...state,
        loading: true,
      };

    case Types.APP_FINISH_LOADING:
      return {
        ...state,
        loading: false,
        error: {
          ...state.error,
          retryDisabled: false,
        },
      };

    case Types.SET_THEME:
      return {
        ...state,
        theme: action.payload.theme,
      };

    case Types.SET_BROWSER:
      return {
        ...state,
        browser: action.payload.browser,
      };

    case Types.APP_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          hasError: true,
          criticalError:
            state.error.criticalError !== true
              ? action.payload.errorObject.criticalError
              : state.error.criticalError,
          type: action.payload.errorObject.type,
        },
        loading: false,
      };

    case Types.APP_RETRY_INCREMENT:
      return {
        ...state,
        error: {
          ...state.error,
          retryCount: state.error.retryCount + 1,
        },
      };

    case Types.DISABLE_RETRY:
      return {
        ...state,
        error: {
          ...state.error,
          retryDisabled: true,
        },
      };

    case Types.CLEAR_ERROR_STATUS:
      return {
        ...state,
        error: {
          ...state.error,
          criticalError: false,
          hasError: false,
          type: '',
        },
      };

    case Types.DISABLE_GOOGLE_CHAT_ALERT:
      return {
        ...state,
        shouldDispatchAlert: false,
      };

    default:
      return state;
  }
}

/**
 * * Actions
 */
export const Creators = {
  appInit: () => ({
    type: Types.APP_INIT,
  }),

  appInitByCardCode: () => ({
    type: Types.APP_INIT_BY_CARDCODE,
  }),

  appFinishLoading: () => ({
    type: Types.APP_FINISH_LOADING,
  }),

  setTheme: theme => ({
    type: Types.SET_THEME,
    payload: { theme },
  }),

  setBrowser: browser => ({
    type: Types.SET_BROWSER,
    payload: { browser },
  }),

  appFailure: errorObject => ({
    type: Types.APP_FAILURE,
    payload: { errorObject },
  }),

  appRetryIncrement: () => ({
    type: Types.APP_RETRY_INCREMENT,
  }),

  disableRetry: () => ({
    type: Types.DISABLE_RETRY,
  }),

  clearErrorStatus: () => ({
    type: Types.CLEAR_ERROR_STATUS,
  }),

  disabledGoogleChatAlert: () => ({
    type: Types.DISABLE_GOOGLE_CHAT_ALERT,
  }),
};
