import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intersection-observer';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import configure from './configure';
import Routes from './routes/routes';
import store from './store';
import setAppVersionToWindow from './util/setAppVersionToWindow';

import './index.css';
import './styles/base.scss';

const init = async () => {
  if (module.hot && process.env.NODE_ENV === 'development') {
    module.hot.accept();
  }

  await configure();
  setAppVersionToWindow();

  ReactDOM.render(
    <Provider store={store}>
      <Routes />
    </Provider>,
    document.getElementById('root')
  );
};

init();
