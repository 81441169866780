import React from 'react';

import PropTypes from 'prop-types';

import LoadingIcon from './assets/page-1-artboard.png';

import './_Loading.scss';

const Loading = ({ loadPrimary }) => (
  <img
    className={`itau-global-loading ${
      loadPrimary ? 'itau-load-primary' : 'itau-load-secondary'
    }`}
    src={LoadingIcon}
    alt="Carregando"
  />
);

Loading.propTypes = {
  loadPrimary: PropTypes.bool,
};

Loading.defaultProps = {
  loadPrimary: true,
};

export default Loading;
