import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import '../config/ReactotronConfig';

import Loading from '../components/Loading/Loading';
import environmentVariables from '../config/environmentVariables';
import DefaultError from '../pages/DefaultError/DefaultError';
import Home from '../pages/Home/Home';
import { Creators as AppActions } from '../store/ducks/app';
import { Creators as ShareActions } from '../store/ducks/share';
import { Creators as WhiteLabelActions } from '../store/ducks/whiteLabel';
import getBrowser from '../util/getBrowser';
import { getQueryParam } from '../util/getQueryParam';
import verifyMainPath from '../util/verifyMainPath';
import './_Main.scss';

const isBrowserIe = getBrowser() === 'ie';

class App extends Component {
  componentDidMount() {
    const { setBrowser, appFailure } = this.props;
    const { DEBUG_MODE, ENVIRONMENT } = environmentVariables;

    // eslint-disable-next-line no-console
    if (DEBUG_MODE) console.log('Ambiente: ', ENVIRONMENT);
    setBrowser(getBrowser());

    if (isBrowserIe) {
      appFailure({
        type: 'incompatible-browser',
        criticalError: true,
      });
    }
  }

  showRetryActionButton = () => {
    const { errorRetryCount } = this.props;

    return errorRetryCount < 2;
  };

  handleRetryActionButtonClick = () => {
    const { errorType, errorRetryCount, appRetryIncrement } = this.props;

    appRetryIncrement();

    if (errorRetryCount < 2) {
      switch (errorType) {
        case 'share-request-error':
          return this.requestGetShareFunction();
        case 'whitelabel-request-error':
          return this.requestDnWhiteLabelFunction();
        default:
          return this.defaultRetryFunction();
      }
    }

    return null;
  };

  defaultRetryFunction = () => {
    const { appInit } = this.props;

    appInit();
  };

  requestGetShareFunction = () => {
    const { appInit } = this.props;

    appInit();
  };

  requestDnWhiteLabelFunction = () => {
    const { whitelabelRequest } = this.props;

    whitelabelRequest();
  };

  setBackAction = () => {
    const { clearErrorStatus } = this.props;

    const key = 'id';
    if (isBrowserIe) {
      return;
    }
    clearErrorStatus();
    window.location.href = `/?${key}=${getQueryParam(key)}`;
  };

  render() {
    const { theme, loading, appHasCriticalError, errorRetryCount } = this.props;

    if (!loading) {
      if (theme !== '') {
        require(`../styles/themes/${theme}.css`);
      } else {
        require('../styles/themes/credicard-theme-default.css');
      }
    }

    if (loading) {
      return (
        <div className="itau-loading-wrapper">
          <Loading loadPrimary={false} />
        </div>
      );
    }

    if (appHasCriticalError || isBrowserIe) {
      return (
        <div className="App credicard-theme-default">
          <DefaultError
            retry={this.showRetryActionButton()}
            retryCount={errorRetryCount}
            retryAction={this.handleRetryActionButtonClick}
            backAction={() => this.setBackAction()}
            removeButton={!verifyMainPath('whitelabel')}
            isBrowserIe={isBrowserIe}
          />
        </div>
      );
    }

    return (
      <div className={`App ${theme || 'credicard-theme-default'}`}>
        <Home />
      </div>
    );
  }
}

App.propTypes = {
  appFailure: PropTypes.func.isRequired,
  setBrowser: PropTypes.func.isRequired,
  errorType: PropTypes.string.isRequired,
  errorRetryCount: PropTypes.number.isRequired,
  appRetryIncrement: PropTypes.func.isRequired,
  whitelabelRequest: PropTypes.func.isRequired,
  clearErrorStatus: PropTypes.func.isRequired,
  appHasCriticalError: PropTypes.bool.isRequired,
  appInit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  appHasCriticalError: state.app.error.criticalError,
  errorType: state.app.error.type,
  errorRetryCount: state.app.error.retryCount,
  whitelabelRequest: state.whiteLabel.whitelabelRequest,
  loading: state.app.loading,
  theme: state.app.theme,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...AppActions,
      ...ShareActions,
      ...WhiteLabelActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
