/**
 * Used to create a array of content from an object by
 * filtering them by their id
 *
 * @param {[{}]} whiteLabelContents: array
 * @param {regex} contentKeyFilter: regex
 * @param {['']} requiredContentTypes: array
 *
 */

const filterContent = (contentObj, filter) => {
  const keys = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const content in contentObj) {
    if (filter.test(content)) {
      const filteredContent = {
        [content]: contentObj[content],
      };
      keys.push(filteredContent);
    }
  }
  return keys;
};

const getContentParameter = (contentName, contentFilter) => {
  // function will return the key position and it's type
  const positionRegex = /\d+(?=\D*$)/;
  const specialCharRegex = /-|_|/g;
  const contentPosition = contentName.match(positionRegex)
    ? contentName.match(positionRegex)[0]
    : '';
  const contentType = contentName
    .replace(contentFilter, '')
    .replace(specialCharRegex, '')
    .replace(positionRegex, '');
  return { contentType, contentPosition };
};

const whiteLabelContentsObjectStructurer = (
  whiteLabelContents,
  contentKeyFilter,
  requiredContentTypes
) => {
  if (!whiteLabelContents || !contentKeyFilter || !requiredContentTypes) {
    return {};
  }
  const structuredObject = [];

  requiredContentTypes.forEach(paramType => {
    const filteredContent = filterContent(
      whiteLabelContents[paramType],
      contentKeyFilter
    );

    filteredContent.forEach(content => {
      const contentName = Object.getOwnPropertyNames(content)[0];
      const { contentPosition, contentType } = getContentParameter(
        contentName,
        contentKeyFilter
      );

      if (contentPosition) {
        structuredObject[contentPosition - 1] = {
          ...structuredObject[contentPosition - 1],
          [contentType]: Object.values(content)[0],
        };
      }
    });
  });

  return structuredObject;
};

export default whiteLabelContentsObjectStructurer;
