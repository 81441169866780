/**
 * Used to normalize the reponse of DN white label service to
 * user data reducer
 *
 * @param {{}} dn
 */

const normalizeTexts = texts => {
  if (!texts) {
    return {};
  }
  const normalizedTexts = texts.reduce((obj, item) => {
    obj[item.id] = {
      ariaHidden: item.ariaHidden,
      ariaLabel: item.ariaLabel,
      hidden: item.hidden,
      text: item.texto,
    };

    return obj;
  }, {});

  return normalizedTexts;
};

const normalizeImages = images => {
  if (!images) {
    return {};
  }
  const normalizedImages = images.reduce((obj, item) => {
    obj[item.id] = item;
    return obj;
  }, {});

  return normalizedImages;
};

const normalizeIcons = icons => {
  if (!icons) {
    return {};
  }
  const normalizedIcons = icons.reduce((obj, item) => {
    obj[item.id] = {
      name: item.nome,
      hidden: item.hidden,
    };
    return obj;
  }, {});

  return normalizedIcons;
};

const normalizeLinks = links => {
  if (!links) {
    return {};
  }
  const normalizedLinks = links.reduce((obj, item) => {
    obj[item.id] = {
      url: item.url,
      text: item.texto,
      hidden: item.hidden,
      ariaLabel: item.ariaLabel,
    };
    return obj;
  }, {});

  return normalizedLinks;
};

const normalizeDnInfo = dnInfo => {
  if (!dnInfo) {
    return {};
  }

  const normalizedDn = {
    campainName: dnInfo.nomeCampanha,
    cardCategory: dnInfo.cartaoCategoria,
    cardDescription: dnInfo.cartaoDescricao,
    imageUrl: dnInfo.imagemUrl,
    logo: dnInfo.logo,
    bin: dnInfo.bin,
    brand: dnInfo.bandeira,
    portfolio: dnInfo.portfolio,
    variant: dnInfo.variante,
    minimumIncome: dnInfo.rendaMinima,
    sku: dnInfo.cartaoCodigo,
    dn: dnInfo.dn,
    name: dnInfo.cartaoDescricao,
    texts: normalizeTexts(dnInfo.whiteLabel.textos),
    images: normalizeImages(dnInfo.whiteLabel.imagens),
    icons: normalizeIcons(dnInfo.whiteLabel.icones),
    links: normalizeLinks(dnInfo.whiteLabel.links),
  };

  return normalizedDn;
};

export default normalizeDnInfo;
