/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';

import PropTypes from 'prop-types';
import './_WebpageHeader.scss';

const WebpageHeader = ({ idComponentContext, logo, alt }) => (
  <div
    id={`lblHeaderWhiteLabel_${idComponentContext}`}
    className="itauheaderwhitelabel-wrapper"
  >
    <div className="container">
      {/* iOS img acessibility workaround */}
      <img className="logo" src={logo} alt={alt} role="img" />
    </div>
  </div>
);

WebpageHeader.propTypes = {
  idComponentContext: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

WebpageHeader.defaultProps = {
  alt: '',
};

export default WebpageHeader;
