import { put, select } from 'redux-saga/effects';

import environment from '../../config/environmentVariables';
import { sendMessage } from '../../endpoints/googleChatMessage';
import { Creators as AppActions } from '../ducks/app';
import { addProduct, setCustom } from './analytics';
import { getShare } from './share';
import { getWhiteLabel, getWhiteLabelByCardCode } from './whiteLabel';

const { GOOGLE_CHAT_API_URL } = environment;

export function* appInit() {
  try {
    yield getShare();
    yield getWhiteLabel();
    yield addProduct();
    yield setCustom();
    yield put(AppActions.appFinishLoading());
  } catch (error) {
    const { shouldDispatchAlert } = yield select(state => state.app);
    if (shouldDispatchAlert) {
      sendMessage(error, 'Saga appInit', GOOGLE_CHAT_API_URL);
    }
  }
}

export function* appInitByCardCode() {
  try {
    yield getWhiteLabelByCardCode();
    yield addProduct();
    yield setCustom();
    yield put(AppActions.appFinishLoading());
  } catch (error) {
    sendMessage(error, 'Saga appInitByCardCode', GOOGLE_CHAT_API_URL);
  }
}
